'use client';

import { Statistic } from 'antd';

const { Countdown } = Statistic;

type CountdownClockProps = {
	startDate?: string | null | undefined;
	endDate?: string | null | undefined;
	time?: string | null | undefined;
};

function CountdownClock({ startDate, endDate, time }: CountdownClockProps) {
	if (!startDate && !endDate && !time) return null;

	let deadline;

	if (time) {
		const timeDate = new Date(time).getTime();
		deadline = Date.now() + timeDate;
	}

	if (endDate) {
		const endDateDate = new Date(endDate).getTime();
		deadline = endDateDate;
	}

	return (
		<Countdown
			value={deadline}
			format={'D[d] H[h] m[m] s[s]'}
			valueStyle={{ color: 'white', fontSize: '1rem' }}
		/>
	);
}

export default CountdownClock;
