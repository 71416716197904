'use client';

import { useEffect, useState } from 'react';

interface EcomSendAppsCommon {
	shop: {
		permanent_domain: string;
		currency: string;
		money_format: string;
		id: number;
	};
	template: string;
}

interface EcomSendApps {
	design_mode: boolean;
	common: EcomSendAppsCommon;
}

interface CustomWindow extends Window {
	EcomSendApps?: EcomSendApps;
}

declare const window: CustomWindow;

function createEcomSendMainStyleEle() {
	const ele = document.createElement('link');
	ele.rel = 'stylesheet';
	ele.href =
		'https://cdn.shopify.com/extensions/f44927a4-d6a7-4ee7-955d-392c3cf3d4b3/ecomsend-popups-19/assets/style.css';
	ele.dataset.ecomsendTag = 'load-alternate-css';
	ele.id = 'ecomsend-main-css';

	console.debug('createEcomSendMainStyleEle');

	if (null === document.getElementById(ele.id)) {
		document.head.appendChild(ele);
	}
}

function createEcomSendMainJSEle() {
	const ele = document.createElement('script');
	ele.defer = true;
	ele.id = 'ecomsend-main-js';
	ele.src =
		'https://cdn.shopify.com/extensions/f44927a4-d6a7-4ee7-955d-392c3cf3d4b3/ecomsend-popups-19/assets/ecomsend.js';

	if (null === document.getElementById(ele.id)) {
		console.debug('createEcomSendMainJSEle');
		document.head.appendChild(ele);
	}
}

function EcomSend() {
	const [isReactScriptLoaded, setIsReactScriptLoaded] = useState(false);
	const [isMobxScriptLoaded, setIsMobxScriptLoaded] = useState(false);

	useEffect(() => {
		createEcomSendMainStyleEle();
		createEcomSendMainJSEle();
	}, []);

	return (
		<>
			<style id="ecomsend-custom-style"></style>
			<div id="ecomsend-widget"></div>
			<div id="ecomsend-scripts">
				<script
					type="text/javascript"
					async
					defer
					src="https://cdn.shopify.com/extensions/f44927a4-d6a7-4ee7-955d-392c3cf3d4b3/ecomsend-popups-19/assets/react_react-dom.min.js"
					onLoad={() => {
						console.debug('react_react');
						setIsReactScriptLoaded(true);
						if (isMobxScriptLoaded) {
							createEcomSendMainJSEle;
						}
					}}></script>
				<script
					type="text/javascript"
					async
					defer
					src="https://cdn.shopify.com/extensions/f44927a4-d6a7-4ee7-955d-392c3cf3d4b3/ecomsend-popups-19/assets/mobx_react-custom-roulette.min.js"
					onLoad={() => {
						setIsMobxScriptLoaded(true);
						if (isReactScriptLoaded) {
							createEcomSendMainJSEle;
						}
					}}></script>
			</div>
		</>
	);
}

export default EcomSend;
