'use client';

import { IconX } from '@tabler/icons-react';
import { cn } from '@features/shared/utils/utils';
import { type ComponentProps, type ReactNode } from 'react';
import { Drawer as DrawerVaul } from 'vaul';

type DrawerProps = {
	trigger?: ReactNode;
	children?: ReactNode;
	bottomClose?: boolean;
	className?: string;
} & ComponentProps<typeof DrawerVaul.Root>;

/**
 * Vaul drawer wrapper with default styling and close button
 */
export function Drawer({ trigger, children, ...rest }: DrawerProps) {
	const isOpeningFromBottom = rest.direction === 'bottom';
	const isOpeningFromRight = rest.direction === 'right';

	return (
		<DrawerVaul.Root shouldScaleBackground {...rest}>
			<DrawerVaul.Trigger asChild>{trigger}</DrawerVaul.Trigger>
			<DrawerVaul.Portal>
				<DrawerVaul.Overlay className="fixed inset-0 z-[120] bg-black/40" />
				<DrawerVaul.Content
					className={cn(
						'scrollable fixed bottom-0 right-0 z-[130] flex-col bg-white pt-10 outline-none',
						isOpeningFromBottom && 'left-0 mt-24 flex max-h-[80vh] min-h-[30vh] rounded-t-[40px]',
						isOpeningFromRight &&
							'top-0 ml-8 flex min-w-[20vw] max-w-[90vw] rounded-l-[40px] xs:ml-16 xs:max-w-[80vw] sm:ml-24'
					)}>
					<DrawerVaul.Close asChild onClick={rest.onClose}>
						<button
							title="close"
							type="button"
							className={cn(
								'absolute z-[2000] flex h-12 w-12 items-center justify-center rounded-full bg-gray-100 shadow-lg',
								isOpeningFromBottom && 'right-1/2 top-0 -translate-y-1/2 translate-x-1/2',
								isOpeningFromRight && 'left-0 top-1/2 -translate-x-1/2 -translate-y-1/2'
							)}>
							<IconX size={16} />
						</button>
					</DrawerVaul.Close>
					<div className="max-h-[80vh] overflow-scroll">
						<div className="mx-auto w-full flex-1 px-4 lg:px-8">{children}</div>
					</div>
				</DrawerVaul.Content>
			</DrawerVaul.Portal>
		</DrawerVaul.Root>
	);
}
